// import { take, call, put, select, takeLatest } from 'redux-saga/effects';
// import { Actions as actions } from '.';

import { Client } from "app/api/Client";
import { put, takeLatest } from "redux-saga/effects";
import { Actions as actions } from ".";
import { listChatGame } from "app/api/actions/game";

// function* doSomething() {}

function* _selectMessage(action) {
  try {
    const { payload, loading } = yield Client.query(listChatGame({
      gameId: action.payload,
    }))

    console.log('payload___', payload);

    if (payload?.code == 200) {
      yield put(actions.fetchMessageComplete(payload.data))
    }
  } catch (error) {
  }

}

export function* globalSliceSaga() {
  yield takeLatest(actions.fetchMessageAction.type, _selectMessage);
}
