import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { globalSliceSaga } from "./saga";
import { GlobalSliceState } from "./types";

export const initialState: GlobalSliceState = {
  inited: false,
  accessToken: '',
  email: '',
  gameId: '',
  categoryId: '',
  categoryName: '',
  categoryTypeId: '',
  messageList: '',
  currentGame: -1,
  gameIdUnread: {
    id: []
  },
};

const slice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    someAction(state, action: PayloadAction<any>) { },

    saveEmail(state, action: PayloadAction<any>) {
      if (!action.payload) {
        return {
          ...state,
          inited: true,
          email: action.payload
        }
      }
      const { email } = action.payload
      console.log('action__saveemail', action.payload)
      return {
        ...state,
        inited: true,
        email: email
      }
    },

    saveAccessToken(state, action: PayloadAction<any>) {
      if (!action.payload) {
        return {
          ...state,
          inited: true,
          accessToken: action.payload
        }
      }
      const { accessToken } = action.payload
      console.log('action__saveAccessToken', action.payload)
      return {
        ...state,
        inited: true,
        accessToken: accessToken
      }
    },

    saveGameId(state, action: PayloadAction<any>) {
      if (!action.payload) {
        return {
          ...state,
          inited: true,
          gameId: action.payload
        }
      }
      const { gameId } = action.payload
      console.log('action__saveGameId', action.payload)
      return {
        ...state,
        inited: true,
        gameId: gameId
      }
    },

    saveCategoryId(state, action: PayloadAction<any>) {
      if (!action.payload) {
        return {
          ...state,
          inited: true,
          categoryId: action.payload
        }
      }
      const { categoryId } = action.payload
      console.log('action___saveCategoryID', action.payload)
      return {
        ...state,
        inited: true,
        categoryId: categoryId
      }
    },

    saveCategoryName(state, action: PayloadAction<any>) {
      if (!action.payload) {
        return {
          ...state,
          inited: true,
          categoryName: action.payload
        }
      }
      const { categoryName } = action.payload
      console.log('action___saveCategoryName', action.payload)
      return {
        ...state,
        inited: true,
        categoryName: categoryName
      }
    },

    saveCategoryTypeId(state, action: PayloadAction<any>) {
      if (!action.payload) {
        return {
          ...state,
          inited: true,
          categoryTypeId: action.payload
        }
      }
      const { categoryTypeId } = action.payload
      console.log('action___SaveCategoryType', action.payload)
      return {
        ...state,
        inited: true,
        categoryTypeId: categoryTypeId
      }
    },

    selectCurrentGame(state, action: PayloadAction<any>) {
      if (!action.payload) {
        return {
          ...state,
          inited: true,
          currentGame: action.payload
        }
      }
      return {
        ...state,
        inited: true,
        currentGame: action.payload,
      }
    },

    saveGameUnread(state, action: PayloadAction<any>) {
      console.log('action.payload__save_id', action.payload, state.gameIdUnread.id);

      if (!action.payload) {
        return {
          ...state,
          inited: true,
          gameIdUnread: {
            ...(state.gameIdUnread || {}),
            id: state.gameIdUnread.id.concat([action.payload])
          }
        }
      }
      return {
        ...state,
        inited: true,
        gameIdUnread: {
          ...(state.gameIdUnread || {}),
          id: state.gameIdUnread.id.concat([action.payload])
        }
      }
    },

    removeGameUnread(state, action: PayloadAction<any>) {
      console.log('state.gameIdUnread____', state.gameIdUnread, action.payload)

      if (!action.payload) {
        return {
          ...state,
          inited: true,
          gameIdUnread: {
            ...state.gameIdUnread,
            id: state.gameIdUnread.id?.filter((_value) => _value !== action.payload)
          }
        }
      }
      return {
        ...state,
        inited: true,
        gameIdUnread: {
          ...state.gameIdUnread,
          id: state.gameIdUnread.id?.filter((_value) => _value !== action.payload)
        }
      }
    },

    fetchMessageAction(state, action: PayloadAction<any>) {
      if (!action.payload) {
        return {
          ...state,
          inited: true,
          gameChat: action.payload
        }
      }
      return {
        ...state,
        inited: true,
        gameChat: action.payload,
      }
    },

    fetchMessageComplete(state, action: PayloadAction<any>) {
      let messageList = action.payload
      let loadingMessage = action.payload != state ? true : false
      return {
        ...state,
        messageList,
        loadingMessage
      }
    },

    appendMessageLiveChat(state, action: PayloadAction<any>) {
      let curritems = (state.messageList.collection || [])
      // let loadingMessage = action.payload != state ? true : false
      return {
        ...state,
        messageList: {
          ...(state.messageList || {}),
          collection: curritems?.concat([action?.payload?.payload?.comment])
        }
        // loadingMessage
      }
    }

  },
});

export const { actions: Actions } = slice;

export const useGlobalSliceSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: globalSliceSaga });
  return { actions: slice.actions };
};

export const { actions: globalSliceActions } = slice;


/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
