/**
 *
 * ChinhSach
 *
 */
import { Typography } from "antd";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

interface Props { }

export const ChinhSach = memo((props: Props) => {
  const { Title } = Typography;
  const { i18n, t } = useTranslation();

  return <Div style={{ padding: '24px 30px' }}>
    <Title style={{ textAlign: 'center' }}>
      {t('policy.policyMain')}
    </Title>
    <Title level={5} style={{ textAlign: 'center', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('policy.policyMain1')}
    </Title>

    <Title level={3} style={{ textAlign: 'left' }}>
      {t('policy.policy1')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy1-1')}
    </Title>

    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy1-2')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy1-3')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy1-4')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy1-5')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy1-6')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy1-7')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy1-8')}
    </Title>

    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy1-9')}
    </Title>

    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy1-10')}
    </Title>

    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy1-11')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy1-12')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy1-13')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('policy.policy1-14')}
    </Title>

    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('policy.policy1-15')}
    </Title>

    <Title level={3} style={{ textAlign: 'left' }}>
      {/* Truy xuất thông tin cá nhân */}
      {t('policy.policy2')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {/* Bất cứ lúc nào quý khách cũng có thể truy cập và chỉnh sửa những thông tin cá nhân của mình theo các liên kết thích hợp mà chúng tôi cung cấp. */}
      {t('policy.policy2-1')}
    </Title>

    <Title level={3} style={{ textAlign: 'left' }}>
      {/* Chia sẻ thông tin cá nhân */}
      {t('policy.policy3')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('policy.policy3-1')}
      {/* Ngoại trừ, sử dụng thông tin cá nhân như đã nêu trong chính sách này, chúng tôi cam kết sẽ không tiết lộ thông tin cá nhân bạn ra ngoài. */}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('policy.policy3-2')}
      {/* Trường hợp có bên thứ ba có liên quan trực tiếp đến việc giao hàng mà quý khách đã mua tại Tepaylink khi đó thông tin của quý khách sẽ được cung cấp cho đơn vị này. */}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('policy.policy3-3')}
      {/* Chúng tôi có thể tiết lộ hoặc cung cấp thông tin cá nhân của bạn trong các trường hợp thật sự cần thiết như sau: (a) khi có yêu cầu của các cơ quan pháp luật; (b) trong trường hợp mà chúng tôi tin rằng điều đó sẽ giúp chúng tôi bảo vệ quyền lợi chính đáng của mình trước pháp luật; (c) tình huống khẩn cấp và cần thiết để bảo vệ quyền an toàn cá nhân của các thành viên Tepaylink */}
    </Title>

    <Title level={3} style={{ textAlign: 'left' }}>
      {/* Bảo mật thông tin cá nhân */}
      {t('policy.policy4')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('policy.policy4-1')}
      {/* Tepaylink đảm bảo mọi thông tin cá nhân của quý khách được lưu giữ an toàn. Chúng tôi bảo vệ thông tin cá nhân của quý khách bằng cách: */}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy4-2')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy4-3')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy4-4')}
      {/* + Xóa thông tin của quý khách khi nó không còn cần thiết cho mục đích lưu trữ hồ sơ của chúng tôi. */}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      + {t('policy.policy4-5')}
      {/* Tuy nhiên do hạn chế về mặt kỹ thuật, không một dữ liệu nào có thể được truyền trên đường truyền internet mà có thể bảo mật được 100%. Do vậy, chúng tôi không thể đưa ra một cam kết chắc chắn rằng thông tin quý khách cung cấp cho chúng tôi sẽ được bảo mật một cách tuyệt đối an toàn, và chúng tôi không thể chịu trách nhiệm trong trường hợp có sự truy cập trái phép thông tin cá nhân của quý khách như các trường hợp quý khách tự ý chia sẻ thông tin với người khác…. */}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('policy.policy4-6')}
      {/* Nếu sử dụng chung với nhiều người quý khách nên đăng xuất hoặc thoát hết tất cả cửa sổ Website đang mở. */}
    </Title>

    <Title level={3} style={{ textAlign: 'left' }}>
      {/* Thay đổi về chính sách */}
      {t('policy.policy5')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {/* Chúng tôi có thể thay đổi nội dung và chỉnh sửa chính sách bảo mật vào bất kỳ lúc nào để phù hợp với nhu cầu của Tepaylink cũng như nhu cầu và phản hồi của khách hàng nếu có. Bất cứ thay đổi nào về chính sách này đều được đăng tải lên trang web của chúng tôi. */}
      {t('policy.policy5-1')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('policy.policy5-2')}
      {/* Nếu quý khách không hài lòng với việc chúng tôi xử lý thắc mắc hay khiếu nại của quý khách, xin vui lòng liên hệ với chúng tôi qua Hotline. */}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('policy.policy5-3')}
      {/* Xin chân thành cảm ơn quý khách! */}
    </Title>

  </Div>;
});

const Div = styled.div``;
