import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    ArrowUpOutlined
} from '@ant-design/icons';
import { Layout, BackTop, Affix } from 'antd';
import React, { ReactNode, useState } from 'react';
import LayoutStyle from './Layout.styles';
import HeaderLayout from './Header';
import FooterLayout from './Footer'


const { Header, Content, Footer, Sider } = Layout;

interface LayoutWrapperProps {
    children: ReactNode
}

const LayoutWrapper = ({ children }: LayoutWrapperProps) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);

    return (
        <LayoutStyle>
            <HeaderLayout />
            {/* <Content className='layout-content'> */}
            <div style={{
                boxShadow: '2px -6px 30px 0px rgba(155,185,207,0.73)'
            }}>
                {children}
            </div>
            {/* </Content> */}
            <FooterLayout />
        </LayoutStyle>
    );
};

export default LayoutWrapper;