import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { commentSliceSaga } from "./saga";
import { CommentSate } from "./types";

export const initialState: CommentSate = {
  listComment: {},
  totolPage: 0
};

const slice = createSlice({
  name: "commentSlice",
  initialState,
  reducers: {
    getListComment(state, action: PayloadAction<any>) {
      return {
        ...state,
        listComment: action?.payload || []
      }
    },
  },
});

export const { actions: commentSilceActions } = slice;

export const useCommentSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: commentSliceSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
