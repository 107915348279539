import { EyeInvisibleOutlined, EyeTwoTone, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Select, Typography, notification } from 'antd';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import LoginWrapper from './Login.style';
import { showAlert } from 'utils/helper';
import { login } from 'app/api/actions/authen';
import { useMutation } from 'react-fetching-library';
import { error } from 'console';
import { useGlobalSliceSlice } from '../../slice';
import { bg_login, logo_tpl } from 'themes';
import './modal.css';
import { GlobalOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';


const { Text } = Typography;
const key_login = 'login';

const DATA_LANGUAGE = [
    { title: "Việt Nam - Tiếng việt", lang: 'vi' },
    { title: "International - English", lang: 'en' },
    { title: "Thailand - Thailand", lang: 'th' }
]

const Login = () => {
    // useInjectReducer({ key: key_login, reducer: reducerLogin });
    // useInjectSaga({ key: key_login, saga: sagaLogin });
    const { actions } = useGlobalSliceSlice();

    const navigate = useNavigate();
    const location: any = useLocation();
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const [api, contextHolder] = notification.useNotification();
    const { state } = useLocation() as any
    const { i18n, t } = useTranslation();
    const { mutate, loading, payload } = useMutation(login)
    const params = (useParams() || {}) as any

    const openNoti = useCallback((type: string, message: string) => {
        api[type]({
            key: 'updatable',
            message: `${t('noti.info')}`,
            description: message || 'Không thành công, vui lòng thử lại',
        })
    }, [])

    console.log('i18n.changeLanguage___', i18n.language);
    console.log('___params___', params);


    const onFinish = useCallback(
        async (value: any) => {
            let { username, password } = value;
            console.log({ username, password })
            // dispatch(login({ username, password }));
            try {
                let { payload } = await mutate({
                    username: username,
                    password: password,
                })
                localStorage.setItem('username', username)
                dispatch(actions.saveEmail(username))
                if (payload?.code == 200) {
                    localStorage.setItem('token', payload.data.token)
                    localStorage.setItem('chatId', payload.data.customer.id)
                    dispatch(actions.saveAccessToken(payload?.data?.token))
                    // showAlert.success('Successfully.');
                    if (state === "logout") {
                        window.history.back()
                    }
                    console.log('state___', state)
                    if (state?.from?.pathname === "/ticket" || state === 'ticket') {
                        navigate('/ticket')
                        return
                    }
                    if (state?.from?.pathname === "/send-ticket" || state === 'send-ticket') {
                        navigate('/send-ticket')
                        return
                    }
                    if (state?.from?.pathname === "/your-tickets" || state === 'your-tickets') {
                        setTimeout(() => {
                            navigate('/your-tickets')
                        }, 1000);
                        return
                    }
                    if (state?.from?.pathname === "/news" || state === 'news') {
                        navigate('/news')
                        return
                    }

                    navigate('/');
                }
                if (payload?.code !== 200) {
                    openNoti('error', payload?.message)
                    console.log('error_login', payload.message);
                }
            } catch (error) {
                console.log('error___', error)
            }
        }, [state]
    );

    const validatePassword = (rule, value, callback) => {
        try {
            callback();
        } catch (err) {
            callback(err)
        }
    };

    return (
        <LoginWrapper>
            {contextHolder}
            <Helmet
                titleTemplate="Đăng nhập - CRM"
                defaultTitle="Đăng nhập - CRM"
            >
                <meta name="description" content="Đăng nhập - CRM" />
            </Helmet>
            {/* {isSpinLogin && (
                <Spin size='large' className="spin-login" />
            )} */}
            <div className="" style={{ position: 'absolute', right: 16, top: 16, zIndex: 10 }}>
                <Select
                    suffixIcon={<GlobalOutlined style={{ fontSize: 18, color: 'white' }} />}
                    style={{ minWidth: 180, color: 'white' }}
                    onChange={(e) => {
                        i18n.changeLanguage(e)
                        localStorage.setItem('lang', e)
                    }}
                    defaultValue={i18n.language}
                    placeholder="Language"
                    options={DATA_LANGUAGE?.map((value) => ({
                        value: value?.lang,
                        label: value?.title
                    }))}>
                </Select>
            </div>
            <Row>
                {/* <Col xs={0} md={0} lg={14} className="col-login-img">
                </Col> */}
                <Col xs={24} md={24} lg={24} className="col-login">
                    <Card className="card" style={{ position: 'relative', overflow: 'hidden' }}>
                        <img
                            style={{ width: '100%', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                            src={bg_login}
                        />
                        <Col className='logo-tplink' xs={0} md={10} lg={10} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <img className='logo-tplink' src={logo_tpl} style={{ height: 200, width: 200, borderRadius: 8 }} />
                        </Col>
                        <Col xs={24} md={14} lg={14} style={{ background: 'rgb(255 255 255/.6)', padding: 16 }}>
                            <Text className="txt-title-brand">{t('login.account')}</Text>
                            <Form
                                form={form}
                                onFinish={onFinish}
                                className="form-brand"
                                layout="vertical"
                            >
                                <Form.Item
                                    name="username"
                                    label={t('login.inputAccount')}
                                    rules={[
                                        { required: true, message: `${t('login.requiredAcc')}` }
                                    ]}
                                >
                                    <Input
                                        className="input-item"
                                        placeholder={t('login.inputAccount')}
                                        disabled={loading}
                                        size="large"
                                        prefix={<UserOutlined style={{ marginRight: 4 }} />}
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label={t('login.inputPassword')}
                                    rules={[
                                        { required: true, message: `${t('login.requiredPass')}` },
                                        { validator: validatePassword }
                                    ]}
                                >
                                    <Input.Password
                                        className="input-item"
                                        placeholder={t('login.inputPassword')}
                                        disabled={loading}
                                        prefix={<SettingOutlined style={{ marginRight: 4 }} />}
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>
                                {/* <Checkbox
                                checked={isChecked}
                                onChange={onChangeRemember}
                                style={{ marginBottom: 20 }}
                            >
                                Nhớ mật khẩu
                            </Checkbox> */}
                                <Form.Item>
                                    <Button
                                        className="btn-brand btn-login"
                                        type="primary"
                                        htmlType="submit"
                                        loading={loading}
                                    >
                                        {t('login.inputBtn')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Card>
                </Col>
            </Row>
        </LoginWrapper >
    );
};

export default Login;