/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'antd/dist/reset.css';

import { GlobalStyle } from 'styles/global-styles';

import { HomePage } from './pages/HomePage/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import Login from './pages/LoginPage';
import { TicketSelect } from './pages/TicketSelect/Loadable';
import { CategoryPage } from './pages/CategoryPage';
import { SendTicket } from './pages/SendTicket';
import { YourTickets } from './pages/YourTickets';
import AuthenRoute from './components/AuthenRoute';
import MainLayout from './pages/MainLayout';
import { bot } from 'themes';
import { Button } from 'antd';
import { LiveChatPage } from './components/LiveChat/Loadable';

export function App() {
  const { i18n, t } = useTranslation();
  const [show, setShow] = React.useState<any>(false)
  React.useEffect(() => {
    const lng = navigator.language
    // i18n.changeLanguage(lng)
  }, [])



  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Trung tâm hỗ trợ"
        defaultTitle="Tepaylink - Trung tâm hỗ trợ"
        htmlAttributes={{ lang: localStorage.getItem('lang') || i18n.language }}
      >
        <meta name="description" content="Tepaylink - Trung tâm hỗ trợ" />
      </Helmet>
      {/* <h2>
        {t('greeting.hello')}
      </h2> */}
      {/* {!!show ?
        <LiveChatPage isShow={show} callBackClose={() => setShow(!show)} /> : <></>
      }
      <div className="" style={{ position: 'fixed', right: 52, bottom: 150, zIndex: 99, cursor: 'pointer' }} onClick={() => {
        setShow(!show)
      }}>
        <img style={{ height: 100, width: 100 }} src={bot} />
      </div> */}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/*"
          element={
            <AuthenRoute>
              <MainLayout />
            </AuthenRoute>
          }
        />
        {/* <Route path="*" element={<NotFoundPage />} />
        <Route path='/ticket' element={<TicketSelect />} />
        <Route path='/category' element={<CategoryPage />} />
        <Route path='/send-ticket' element={<SendTicket />} />
        <Route path='/your-tickets' element={<YourTickets />} /> */}
      </Routes>
      <GlobalStyle />
    </BrowserRouter>
  );
}
