import React, { memo, useEffect, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from 'app/components/NotFoundPage/Loadable';
import { TicketSelect } from '../TicketSelect/Loadable';
import { CategoryPage } from '../CategoryPage/Loadable';
import { SendTicket } from '../SendTicket/Loadable';
import { YourTickets } from '../YourTickets';
import LayoutWrapper from './Layout';
import { HomePage } from '../HomePage/Loadable';
import { NewsPage } from '../NewsPage/Loadable';
import { DieuKhoan } from '../DieuKhoan'
import { ChinhSach } from '../ChinhSach';
import { NewsDetailPage } from '../NewsDetailPage/Loadable';
import { SocketProvider } from 'app/contexts/SocketContext';

import { bot } from 'themes';
import { LiveChatPage } from 'app/components/LiveChat';
import ModalGameChat from './ModalGameChat';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalSliceSlice } from 'app/slice';
import { saveGameUnread } from 'app/slice/selectors';
import { useParameterizedQuery } from 'react-fetching-library';
import { readLiveChat } from 'app/api/actions/game';
import Draggable from 'react-draggable';

const Layout = () => {
  const [show, setShow] = React.useState<any>(false)
  const [showGame, setShowGame] = React.useState<any>(false)
  const [gameId, setGameId] = React.useState<any>()

  const [widthReality, setWidthReality] = useState({
    width: window.innerWidth || 0
  })
  const dispatch = useDispatch()
  const { actions } = useGlobalSliceSlice();
  const gameUnread = useSelector(saveGameUnread)
  const { payload, query } = useParameterizedQuery(readLiveChat)

  const [position, setPosition] = useState({ x: 0, y: 0 });
  console.log('gameUnread___', gameUnread);

  const handleDrag = (e, data) => {
    setPosition({ x: data.x, y: data.y });
  };

  return (
    <SocketProvider>
      <LayoutWrapper>
        <ModalGameChat
          gameUnread={gameUnread}
          callBackGame={(gameId) => {
            setGameId(gameId)
            setShow(true)
            setShowGame(false)
            dispatch(actions.fetchMessageAction(gameId))
            dispatch(actions.selectCurrentGame(gameId))
            dispatch(actions.removeGameUnread(gameId))
            query({ gameId: gameId })
          }}
          widthReality={widthReality?.width}
          open={showGame}
          setOpen={setShowGame}
          closeModal={() => setShowGame(false)} />
        {!!show ?
          <LiveChatPage gameId={gameId} isShow={show} callBackClose={() => setShow(!show)} /> : <></>
        }
        <Draggable
          axis="both"
          bounds="parent"
          defaultPosition={{ x: 0, y: 0 }}
          position={position}
          grid={[5, 5]}
          onMouseDown={(e) => {
            // setShowGame(!showGame)
          }}
          onStart={() => {
            setShowGame(!showGame)
          }}
          onDrag={handleDrag}
        >
          <div className="" style={{ position: 'fixed', right: 52, bottom: 150, zIndex: 9999, cursor: 'pointer' }}>
            {gameUnread?.id?.length > 0 && <div style={{ height: 12, width: 12, borderRadius: 100, backgroundColor: 'red', position: 'absolute', top: 20 }} />}
            {!!show ? <></> :
              <img style={{ height: 70, width: 70 }} src={bot} />
            }
          </div>
        </Draggable>
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path='/ticket' element={<TicketSelect />} />
          <Route path='/category' element={<CategoryPage />} />
          <Route path='/send-ticket' element={<SendTicket />} />
          <Route path='/your-tickets' element={<YourTickets />} />
          <Route path='/news' element={<NewsPage />} />
          <Route path='/dieu-khoan' element={<DieuKhoan />} />
          <Route path='/chinh-sach' element={<ChinhSach />} />
          <Route path='/news-detail' element={<NewsDetailPage />} />
          {/* <Route path={'/'} element={<HomePage />} /> */}
        </Routes>
      </LayoutWrapper>
    </SocketProvider>
  );
};

export default memo(Layout);
