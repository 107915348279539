import {
    LogoutOutlined,
    SettingOutlined, ShopOutlined
} from '@ant-design/icons';
import { Avatar, Dropdown, Menu, Spin, Badge, Typography, Button, notification, Space, Select } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderStyle, { MenuHeaderWrapper } from './Header.style';
import { selectGlobalSlice } from 'app/slice/selectors';
import { useGlobalSliceSlice } from 'app/slice';
import { useNavigate } from 'react-router';
import { Header } from 'antd/es/layout/layout';
import { useParameterizedQuery } from 'react-fetching-library';
import { listGame } from 'app/api/actions/game';
import { game_development } from 'themes';
import ModalGame from './components/ModalGame';
import { GlobalOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const DATA_LANGUAGE = [
    { title: "VIE", lang: 'vi' },
    { title: "ENG", lang: 'en' },
    { title: "THA", lang: 'th' }
]

const MenuHeaderDropdown = () => {
    const { i18n, t } = useTranslation();
    const { actions } = useGlobalSliceSlice()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return <MenuHeaderWrapper>
        <Menu className="menu-header" selectedKeys={[]}>
            {/* <Menu.Item key="settings" style={{ height: 50 }}
                onClick={() => navigate("/reset-password")}
            >
                <SettingOutlined style={{ position: 'relative', top: -3, marginRight: 15 }} />
                Đổi mật khẩu
            </Menu.Item> */}
            {/* <Menu.Divider /> */}
            <Menu.Item key="logout" style={{ color: 'red', height: 40 }}
                onClick={e => {
                    // dispatch(logout())
                    localStorage.removeItem('token')
                    dispatch(actions.saveAccessToken(""))
                    navigate('/login', { state: "logout" })
                }}
            >
                <LogoutOutlined style={{ position: 'relative', top: -3, marginRight: 15 }} />
                {t('tickets.logOut')}
            </Menu.Item>
        </Menu>
    </MenuHeaderWrapper>
};

const HeaderLayout = () => {
    const { query, payload, loading } = useParameterizedQuery(listGame)

    const [api, contextHolder] = notification.useNotification();

    const token = localStorage.getItem('token')
    const gameIdLocal = localStorage.getItem('gameId')
    const { gameId } = useSelector(selectGlobalSlice)

    const { Title } = Typography;
    const navigate = useNavigate()
    const [moreGame, setMoreGame] = React.useState<boolean>(false)
    const dispatch = useDispatch()
    const { actions } = useGlobalSliceSlice();
    const { i18n, t } = useTranslation();

    React.useEffect(() => {
        query({ search: '' })
    }, [])

    const openNoti = React.useCallback((type: string) => {
        api[type]({
            key: 'updatable',
            message: 'Thông báo',
            description: 'Vui lòng chọn sản phẩm',
        })
    }, [])

    const _clickTicket = React.useCallback(() => {
        if (!gameIdLocal) {
            openNoti('error')
            setMoreGame(true)
            return
        }
        if (!token) {
            navigate('/login', { state: 'your-tickets' })
            return
        }
        navigate('/your-tickets')
    }, [gameId, gameIdLocal])

    const openGame = React.useCallback(() => {
        setMoreGame(true)
    }, [moreGame])

    const _saveModal = React.useCallback((game: any) => {
        if (!!game && game?.id) {
            dispatch(actions.saveGameId({
                gameId: game?.id
            }))
            localStorage.setItem('gameId', game?.id)
            navigate('/login', { state: 'your-tickets' })
        }
        // navigate('/your-tickets')
        setMoreGame(false)
    }, [])



    return (
        <HeaderStyle >
            {contextHolder}
            <ModalGame closeModal={(game) => _saveModal(game)} open={moreGame} setOpen={openGame} />
            <Header className='header-container' style={{ backgroundColor: 'white' }}>
                <div className="header">
                    <Title className='navTitle' onClick={() => navigate('/')} style={{ margin: 0, cursor: 'pointer' }} level={3}>SUPPORT</Title>
                    <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                        <Button className='homeTitle' onClick={() => navigate('/')} type="text" block>
                            {/* Trang chủ */}
                            {t('header.home')}
                        </Button>
                        <Button onClick={() => _clickTicket()} type="text" block>
                            {/* Yêu cầu đã gửi */}
                            {t('header.ticket')}
                        </Button>
                        {/* <Space style={{}} >
                            <img style={{ height: 40, width: 40, zIndex: 10, borderRadius: 50, marginLeft: 8 }} src={payload?.data?.collection?.find((_game: any) => _game?.id == gameIdLocal || _game?.id == gameId)?.icon_url || game_development} />
                        </Space> */}
                        <Dropdown
                            overlay={<MenuHeaderDropdown />}
                            placement="bottomLeft"
                            arrow
                        >
                            <div className='header-avatar-wrapper'>
                                <Badge status="success" dot={true} offset={[-9, 48]}>
                                    <Avatar
                                        className="header-avatar"
                                        alt="avatar"
                                        style={{ height: 40, width: 40, }}
                                        src={payload?.data?.collection?.find((_game: any) => _game?.id == gameIdLocal || _game?.id == gameId)?.icon_url || game_development}
                                    />
                                </Badge>
                            </div>
                        </Dropdown>
                        <Select
                            suffixIcon={<GlobalOutlined style={{ fontSize: 18, color: 'white' }} />}
                            style={{ color: 'white', minWidth: 100, marginLeft: 16 }}
                            onChange={(e) => {
                                i18n.changeLanguage(e)
                                localStorage.setItem('lang', e)
                            }}
                            placeholder="Language"
                            defaultValue={i18n.language}
                            options={DATA_LANGUAGE?.map((value) => ({
                                value: value?.lang,
                                label: value?.title
                            }))}>
                        </Select>
                    </div>
                </div>
            </Header>
        </HeaderStyle>
    );
};

export default HeaderLayout;