/**
 *
 * Asynchronously loads the component for NewsDetailPage
 *
 */

import { lazyLoad } from "utils/loadable";

export const NewsDetailPage = lazyLoad(
  () => import("./index"),
  (module) => module.NewsDetailPage
);
