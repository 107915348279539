import styled from 'styled-components';

export default styled.div`
    cursor: pointer;

    .header-container {
        -webkit-box-shadow: 2px 15px 30px 0px rgba(155,185,207,0.73);
        box-shadow: 2px 15px 30px 0px rgba(155,185,207,0.73);
        z-index: 10;
    }

    .header {
        display: flex;
        align-items: center; 
        background-color: white;
        max-width: 1140px;
        justify-content: space-between;
        margin: auto;
    }

    .ant-select-selection-item {
        color: white
    }

    .ant-select-selection-placeholder {
        color: white
    }

    .ant-select-selector {
        background: linear-gradient(90deg, rgb(0, 97, 175) 0%, rgb(25, 179, 236) 100%) !important
    }
    @media (max-width: 780px) {
        .homeTitle {
            display: none
        }
    }

    @media (max-width: 840px) {
        .header-container {
            padding: 0px 16px
        }

        .header {
            justify-content: space-between;
            align-items: center
        }

        .navTitle {
            font-size: 12px
        }
    }
`;

export const MenuHeaderWrapper = styled.div`
    .menu-header {           
        border: 1px solid #e5e5e5;
        width: 15 0px;        
        border-radius: 6px;
        box-shadow: 0 8px 10px rgba(0,0,0,.08);
    }
`;