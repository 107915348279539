import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state?.globalSlice || initialState;

export const selectGlobalSlice = createSelector(
  [selectSlice],
  state => state,
);

export const selectMessages = createSelector(
  [selectSlice],
  (state) => state.messageList);

export const selectCurrentGame = createSelector([selectSlice], (state) => state.currentGame)

export const saveGameUnread = createSelector([selectSlice], (state) => state.gameIdUnread)

export const removeGameUnread = createSelector([selectSlice], (state) => state.gameIdUnread)
