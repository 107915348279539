import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en/translation.json';
import vi from './en/translationVi.json';
import th from './en/translationTh.json'

import { convertLanguageJsonToObject } from './translations';
import HttpBackend from 'i18next-http-backend';
import { useEffect } from 'react';
export const translationsJson = {
  en: {
    translation: en,
  },
  vi: {
    translation: vi,
  },
  th: {
    translation: th
  }
};
const Languages = ['en', 'de'];

// if ("geolocation" in navigator) {
//   navigator.geolocation.getCurrentPosition(function (position) {
//     const latitude = position.coords.latitude;
//     const longitude = position.coords.longitude;
//     fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyA05tJMsKW5fC_tTB3aYZme1LQn1mGKFCg`)
//       .then(response => response.json())
//       .then(data => {
//         const addressComponents = data.results[0].address_components;
//         let countryCode = null;
//         addressComponents.forEach(component => {
//           if (component.types.includes('country')) {
//             countryCode = component.short_name;
//           }
//         });
//         console.log('addressComponents___', addressComponents);

//         console.log("Mã quốc gia:", countryCode);
//       })
//       .catch(error => {
//         console.error("Lỗi khi lấy dữ liệu địa chỉ:", error);
//       });
//   });
// } else {
//   console.log("Trình duyệt không hỗ trợ Geolocation.");
// }

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(en);

export const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: translationsJson,
    lng: !!localStorage.getItem('lang') ? `${localStorage.getItem('lang')}` : 'vi', // default language
    fallbackLng: `${localStorage.getItem('lang')}` || 'vi',
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
