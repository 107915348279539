import { selectCurrentGame } from "app/slice/selectors";
import { useGlobalSliceSlice } from "../slice";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Socket, io } from "socket.io-client";

type ContextProps = {
    sendSocket: (topic: string, data: any) => void,
};

export const SocketContext = React.createContext<Partial<ContextProps>>({});

export const SocketProvider = ({ children }: any) => {
    const dispatch = useDispatch()
    const { actions } = useGlobalSliceSlice();
    const token = localStorage.getItem('token')
    const chatId = localStorage.getItem('chatId')
    const currentGame = useSelector(selectCurrentGame)
    console.log('token___', token);


    const _currentSocket = React.useRef<Socket>()

    const _sendSocket = useCallback((topic: string, data: any) => {
        _currentSocket.current?.emit(topic, data)
    }, [])

    React.useEffect(() => {
        console.log('Main layout render')

        _currentSocket.current = io(process.env.REACT_APP_CHATTING_SOCKET_URL || '', {});

        _currentSocket.current.on('connect', function () {
            _currentSocket.current?.emit("identity-customer", `${token}`)

        });

        _currentSocket.current.on('connection', (socket) => {
            socket.join(`private/customer/${chatId}`)
            socket.to(`private/customer/${chatId}`).emit('identity-customer', `${token}`)
        });

        // _currentSocket.current.on('connect', function () {
        //     console.log('Connected');
        //     _currentSocket.current?.emit("count-unead-conversation")

        // });
        _currentSocket.current.on('message', function (data) {
            console.log('event__data', data);
            console.log('currentGame___', currentGame);

            try {
                if (data.event == "NEW_MESSAGE_CHAT_SUPPORT") {
                    const { gameId, comment } = data?.data?.payload
                    if (comment.created_by?.type == "admin") {
                        dispatch(actions.saveGameUnread(gameId))
                    }
                    if (parseInt(gameId) == currentGame) {
                        dispatch(actions.appendMessageLiveChat(data.data))
                    }
                }
            } catch (error) {

            }
        });
        _currentSocket.current.on('exception', function (data) {
            console.log('event__exception', data);
        });
        _currentSocket.current.on('disconnect', function (err) {
            console.log('2Disconnected', err);
        });
        _currentSocket.current.on('error', function () {
            console.log('error');
        });
        return () => {
            if (!!_currentSocket.current && _currentSocket.current.connected) {
                _currentSocket.current.disconnect()

                _currentSocket.current = undefined;

            }
        }
    }, [chatId, token, currentGame])

    const value = useMemo(() => {
        return {
            sendSocket: _sendSocket
        }
    }, [])
    return (
        <SocketContext.Provider
            value={value}>
            {children}
        </SocketContext.Provider>
    );
}