import * as React from 'react';
import styled from 'styled-components/macro';
import { P } from './P';
import { Helmet } from 'react-helmet-async';
import { Header } from 'antd/es/layout/layout';
import { Segmented, Spin, Typography, Upload, message } from 'antd';
import {
  CloseOutlined
} from '@ant-design/icons';
import { Input, MessageList } from 'react-chat-elements';
import moment from 'moment';
import './liveChat.css'
import { album, file, sent } from 'themes';
import { useMutation, useParameterizedQuery } from 'react-fetching-library';
import { gameLiveChat, listChatGame, sendLiveChat } from 'app/api/actions/game';
import { RcFile } from 'antd/es/upload';
import { uploadImage } from 'app/api/actions/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalSliceSlice } from '../../slice';
import { selectGlobalSlice } from '../../slice/selectors';
import { useTranslation } from 'react-i18next';

interface ChatProps {
  isShow?: any,
  callBackClose?: () => void,
  gameId?: any
}



export const LiveChatPage = React.memo((props: ChatProps) => {
  const { Title } = Typography;
  const messageListReferance = React.createRef();
  const inputReferance = React.createRef<any>();
  const gameIdLocal = localStorage.getItem('gameId')
  const { payload: payloadGame, query: queryGame } = useParameterizedQuery(gameLiveChat)
  const { query: queryChatGame, payload: payloadChatGame } = useParameterizedQuery(listChatGame)
  const { mutate: mutateUpload, loading: uploading } = useMutation(uploadImage)

  const { mutate, payload, loading } = useMutation(sendLiveChat)
  const [upload, setUpload] = React.useState<string>()

  const [showInfoFile, setShowInfoFile] = React.useState<boolean>(true)

  const messagesEndRef = React.useRef<any>();

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  const { actions } = useGlobalSliceSlice();
  const { messageList } = useSelector(selectGlobalSlice)
  const dispatch = useDispatch()
  const { i18n, t } = useTranslation();

  const [widthReality, setWidthReality] = React.useState({
    width: window.innerWidth || 0
  })
  React.useEffect(() => {
    queryGame({})
    setTimeout(() => {
      scrollToBottom()
    }, 500);
  }, [])


  console.log('props_____???:', props?.gameId);


  const _messages = React.useMemo(() => {
    if (!messageList) {
      return []
    }
    return messageList?.collection.slice()?.sort?.((a, b) => a?.id - b?.id)?.map(__ => {
      let checkImg = __?.files?.[0]?.file_name?.endsWith('.png') || __?.files?.[0]?.file_name?.endsWith('.jpeg') || __?.files?.[0]?.file_name?.endsWith('.jpg')
      if (__?.body?.length > 0) {
        return {
          // avatar: logo,
          position: __?.created_by?.type == "customer" ? 'right' : 'left',
          type: "text",
          text: !!checkImg ?
            <a href={`${__?.files?.[0]?.file_url}`} className="" target="_blank">
              {__?.body}
              <img style={{ objectFit: 'cover', maxWidth: '100%' }} src={__?.files?.[0]?.file_url} />
            </a>
            : <div className="">
              <p style={{ margin: 0 }} className="">{`${__?.body}`}</p>
              <a href={`${__?.files?.[0]?.file_url}`} className="" target="_blank">
                {__?.files?.[0]?.file_url}
              </a>
            </div>,
          id: __.id,
          data: __?.files?.[0]?.file_url,
          dateString: moment(__?.created_at).format('DD-MM-YYYY HH:mm'),
          date: moment(__?.created_at).format('DD-MM-YYYY HH:mm'),
        }
      }
      if (__?.files?.length > 0) {
        return {
          // avatar: logo,
          position: __?.created_by?.type == "customer" ? 'right' : 'left',
          type: "text",
          text: !!checkImg ?
            <a href={`${__?.files?.[0]?.file_url}`} className="" target="_blank">
              <img style={{ objectFit: 'cover', maxWidth: '100%' }} src={__?.files?.[0]?.file_url} />
            </a>
            : <a href={`${__?.files?.[0]?.file_url}`} className="" target="_blank">
              {__?.files?.[0]?.file_url}
            </a>,
          id: __.id,
          // data: __?.files?.[0]?.file_url,
          dateString: moment(__?.created_at).format('DD-MM-YYYY HH:mm'),
          date: moment(__?.created_at).format('DD-MM-YYYY HH:mm'),
        }
      }
    })
  }, [messageList])

  const beforeUpload = (file: RcFile) => {
    const isCheckFile = file.type === 'video/mp4' || file.type === 'video/x-m4v' || file.type === "video/quicktime" || file.type === 'video/*';
    // if (!isCheckFile && accept === "video/mp4,video/x-m4v,video/*") {
    //     message.error('You can only upload Video file!');
    //     return isCheckFile;
    // }
    const isLt20M = file.size < 20480000;
    if (!isLt20M) {
      message.error('File must smaller!');
      return isLt20M
    }
  };

  const _removeFile = React.useCallback(() => {
    setUpload(undefined)
    console.log('remove_____');
  }, [upload])


  return (
    <>
      <Wrapper style={{
        position: 'fixed', right: 16, bottom: 16, width: 350, height: 420, zIndex: 999,
        borderRadius: 16,
        border: '1px solid #BEC6C8', backgroundColor: 'white'
      }}>
        <Header style={headerStyle}>
          <Title style={{ fontSize: 'calc(18px)', color: 'white', fontWeight: 700, margin: 0, paddingLeft: 16 }}>
            {t('chat.chat')}
          </Title>
          <CloseOutlined onClick={props?.callBackClose} style={{ fontSize: 20, padding: 16 }} />
        </Header>
        <div className='scrollChat' style={{ marginTop: 66, height: '100%', overflowY: 'scroll', backgroundColor: '#f3f3f3', borderBottomLeftRadius: 16, borderBottomRightRadius: 16, width: '100%' }} >
          {/* <Segmented className='segmentGame' style={{ width: 346, overflowX: 'scroll', paddingTop: 8 }}
            onChange={async (value) => {
              setGameId(value)
              dispatch(actions.fetchMessageAction(value))
              dispatch(actions.selectCurrentGame(value))
              // queryChatGame({ gameId: value })
              setTimeout(() => {
                scrollToBottom()
              }, 1000);
            }}
            options={payloadGame?.data?.games?.map((__, index) => {
              return ({
                label: (
                  <div style={{ width: 200, padding: 0 }}>
                    <img style={{
                      height: 100, width: 200, objectFit: 'cover',
                      borderTopLeftRadius: 16, borderTopRightRadius: 16
                    }} src={__?.icon_url} />
                    <Title style={{ fontSize: 'calc(16px)', color: 'black', paddingTop: 8 }}>
                      {__?.name}
                    </Title>
                  </div>
                ),
                value: __?.id,
              })
            })} /> */}
          <div className='messListChat' style={{ flex: 1, overflow: 'scroll' }}>
            {_messages?.length > 0 ?
              <MessageList
                // messageBoxStyles={{
                //   overflowY: 'scroll',
                //   scrollBehavior: 'smooth',
                //   height: '300px'
                // }}
                className='messageList'
                lockable={true}
                toBottomHeight={'100%'}
                referance={messageListReferance}
                dataSource={_messages as any}
                messageBoxStyles={{ marginTop: 12, padding: 12, maxWidth: '60%' }}
              /> : <Title style={{ fontSize: 'calc(18px)', color: 'black', fontWeight: 700, margin: 0, paddingLeft: 16, width: '100%', textAlign: 'center', padding: 8 }}>Chat để bắt đầu!</Title>
            }
            <div ref={messagesEndRef} />
          </div>
          {!!props?.gameId &&
            <Input
              onKeyPress={(event) => {
                if (event?.code === "Enter") {
                  mutate({
                    gameId: props?.gameId,
                    formValues: {
                      body: inputReferance.current?.value,
                      files: [],
                    }
                  })
                  inputReferance.current.value = ""
                  setTimeout(() => {
                    scrollToBottom()
                  }, 1000);
                }
              }}
              referance={inputReferance}
              // value={quickChat}
              inputStyle={{
                height: 40, borderWidth: 0
              }}
              placeholder='Nội dung trao đổi...'
              // multiline={true}
              maxHeight={100}
              className="inputContainer"
              leftButtons={
                <Upload
                  // onRemove={() => _removeFile()}
                  // beforeUpload={beforeUpload}
                  // accept={accept}
                  // itemRender={itemRender}
                  showUploadList={false}
                  name="file"
                  maxCount={1}
                  listType={'text'}
                  multiple={false}
                  // onPreview={handlePreview}
                  customRequest={async ({ onSuccess, onError, file }) => {
                    console.log('file___', file)
                    try {
                      let formData = new FormData()
                      formData.append("file", file)
                      let { payload, status } = await mutateUpload(formData)
                      console.log('payload____', payload);

                      if (!!payload && payload?.code == 200) {
                        mutate({
                          gameId: props?.gameId,
                          formValues: {
                            body: "",
                            files: [payload?.data?.file_name],
                          }
                        })
                        setShowInfoFile(true)
                        // form.setFieldValue(payload?.file_name, payload?.file_url)
                        onSuccess?.(payload)
                        return
                      }
                      onError?.("Tải file không thành công." as any)
                    } catch (error) {
                      onError?.("Tải file không thành công." as any)
                    }
                  }}
                >
                  <div style={{ paddingRight: 16, paddingLeft: 8 }}><img style={{ height: 30, width: 30 }} src={file} /></div>
                </Upload>
              }
              rightButtons={
                <>
                  {!!loading ? <Spin size="large" style={{ paddingRight: 8, paddingLeft: 18 }} /> :
                    <div className='sendMess' onClick={() => {
                      mutate({
                        gameId: props?.gameId,
                        formValues: {
                          body: inputReferance.current?.value,
                          files: [],
                        }
                      })
                      inputReferance.current.value = ""
                    }} style={{ paddingRight: 8, paddingLeft: 16 }}><img style={{ height: 32, width: 32 }} src={sent} /></div>
                  }
                </>
              }
            />
          }
        </div>
      </Wrapper>
    </>
  );
})

const headerStyle: React.CSSProperties = {
  color: '#fff',
  height: 64,
  paddingInline: 48,
  lineHeight: '64px',
  background: 'linear-gradient(157deg, rgba(20,34,100,1) 38%, rgba(1,149,255,1) 100%)',
  position: 'absolute',
  top: 0,
  left: 0, right: 0,
  borderTopLeftRadius: 16, borderTopRightRadius: 16,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 16px'
  // padding: '15px 0px'
};

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 320px;
`;

const Title = styled.div`
  margin-top: -8vh;
  font-weight: bold;
  color: black;
  font-size: 3.375rem;

  span {
    font-size: 3.125rem;
  }
`;
