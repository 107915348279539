import querystring from 'querystring'

export const listGame = ({ search, }: { search: any }): any => {
  let endpoint = `/api/game?filter[search]=${search}`

  return {
    api_type: 'API',
    method: 'GET',
    endpoint
  }
}

export const categoryGameId = ({ gameId }: { gameId: number }): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/game/${gameId}/category`
})

export const listTicket = ({ gameId, category_id }: { gameId: number, category_id: number }): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/game/${gameId}/category/${category_id}/type`
})

export const listFormTicket = ({ gameId, category_id, category_type_id }: { gameId: number, category_id: number, category_type_id: number }): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/game/${gameId}/category/${category_id}/type/${category_type_id}`
})

export const sendTicket = (
  payload: any
): any => {
  return {
    api_type: 'API',
    method: 'POST',
    endpoint: `/api/ticket`,
    body: payload
  }
};

export const listTicketSender = ({ gameId, status }: { gameId: any, status?: string }): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/game/${gameId}/ticket?filter[status]=${status}`
})

export const ticketDetailSender = ({ gameId, ticketCode }: { gameId: number, ticketCode: number }): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/game/${gameId}/ticket/${ticketCode}`
})


export const sendCommentTickets = ({ formValues, gameId, ticketCode }: { formValues: any, gameId: number, ticketCode: number }): any => {
  return {
    api_type: 'API',
    method: 'POST',
    endpoint: `/api/game/${gameId}/ticket/${ticketCode}/comment`,
    body: formValues
  }
};

export const getListComment = ({ gameId, ticketCode, page, perPage }: { gameId: number, ticketCode: number, page: number, perPage: number }): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/game/${gameId}/ticket/${ticketCode}/comment?${querystring.stringify({ page, perPage })}`
})

export const getListStatus = ({ }: {}): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/ticket/const`
})

export const getInfoAccountName = ({ gameId }: { gameId: number }): any => ({
  api_type: 'API',
  method: 'POST',
  endpoint: `/api/game/${gameId}/info`
})

export const getPropertyDefault = ({ }: {}): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/admin/category-property/const`
})

export const gameLiveChat = ({ }: {}): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/livechat`
})

export const listChatGame = ({ gameId }: { gameId: any }): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/livechat/${gameId}/messages`
})

export const sendLiveChat = ({ formValues, gameId }: { formValues: any, gameId: any }): any => ({
  api_type: 'API',
  method: 'POST',
  endpoint: `/api/livechat/${gameId}/messages`,
  body: formValues
})

export const readLiveChat = ({ gameId }: { gameId: any }): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/livechat/${gameId}/messages/read`
})

export const readComment = ({ gameId, ticketCode }: { gameId: any, ticketCode: any }): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/game/${gameId}/ticket/${ticketCode}/read
  `
})
