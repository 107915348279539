/**
 *
 * Asynchronously loads the component for SendTicket
 *
 */

import { lazyLoad } from "utils/loadable";

export const SendTicket = lazyLoad(
  () => import("./index"),
  (module) => module.SendTicket
);
