/**
 *
 * DieuKhoan
 *
 */
import { Typography } from "antd";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

interface Props { }

export const DieuKhoan = memo((props: Props) => {
  const { Title } = Typography;
  const { i18n, t } = useTranslation();

  return <Div style={{ padding: '24px 30px' }}>
    <Title style={{ textAlign: 'center' }}>
      {t('terms.termsMain')}
    </Title>

    <Title level={3} style={{ textAlign: 'left' }}>
      {t('terms.terms1')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('terms.terms1-1')}
    </Title>

    <Title level={3} style={{ textAlign: 'left' }}>
      {t('terms.terms2')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('terms.terms2-1')}
    </Title>

    <Title level={3} style={{ textAlign: 'left' }}>
      {t('terms.terms3')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('terms.terms3-1')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('terms.terms3-2')}
    </Title>


    <Title level={3} style={{ textAlign: 'left' }}>
      {t('terms.terms4')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('terms.terms4-1')}
    </Title>
    <Title level={3} style={{ textAlign: 'left' }}>
      {t('terms.terms5')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('terms.terms5-1')}
    </Title>
    <Title level={3} style={{ textAlign: 'left' }}>
      {t('terms.terms6')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('terms.terms6-1')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('terms.terms6-2')}
    </Title>

    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('terms.terms6-3')}
    </Title>

    <Title level={3} style={{ textAlign: 'left' }}>
      {t('terms.terms7')}
    </Title>
    <Title level={5} style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 'calc(14px)' }}>
      {t('terms.terms7-1')}
    </Title>
  </Div>;
});

const Div = styled.div``;
