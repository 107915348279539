import {
    AlignLeftOutlined
} from '@ant-design/icons';
import { Layout, Row, Typography } from 'antd';
import React, { memo } from 'react';
import { bg, logo_tpl } from 'themes';
import FooterStyle from './Footer.style';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';



const FooterLayout = () => {
    const { Header, Content, Footer, Sider } = Layout;
    const { Title } = Typography;
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();

    return (
        <FooterStyle>
            <Footer className='footer' style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'repeat-x' }}>
                <div className="" style={{ maxWidth: '1140px', margin: 'auto' }}>
                    <Row justify="space-between" align={'middle'}>
                        <div className="">
                            {/* <AlignLeftOutlined style={{ fontSize: 20 }} />
                            <Title style={{ fontWeight: 'normal', fontSize: 14 }}>
                                Tiếng việt
                            </Title> */}
                            <img className='logo-tplink' src={logo_tpl} style={{ height: 60, width: 60, borderRadius: 8 }} />
                        </div>
                        <div className="">
                            <a className="" >
                                <Title onClick={() => navigate('/dieu-khoan')} style={{ fontWeight: 'normal', fontSize: 14, color: 'rgb(69, 130, 192)' }}>
                                    {t('footer.rules')}
                                </Title>
                            </a>
                            <a className="" >
                                <Title onClick={() => navigate('/chinh-sach')} style={{ fontWeight: 'normal', fontSize: 14, color: 'rgb(69, 130, 192)' }}>
                                    {t('footer.security')}
                                </Title>
                            </a>
                            <Title style={{ fontWeight: 'normal', fontSize: 14 }}>
                                Copyright © {`${moment().format('YYYY')}`} TEPAYLINK
                            </Title>
                        </div>
                    </Row>
                </div>
            </Footer>
        </FooterStyle>
    )
};

export default memo(FooterLayout);