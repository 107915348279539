/**
 *
 * Asynchronously loads the component for ShowTickets
 *
 */

import { lazyLoad } from "utils/loadable";

export const TicketSelect = lazyLoad(
  () => import("./index"),
  (module) => module.TicketSelect
);
