import styled from 'styled-components';

export default styled.div`
margin: auto;   
min-height: 100vh;
position: relative;   

.d-flex {
  display: flex;
  flex-direction: row
}

.header-container {
  -webkit-box-shadow: 2px 15px 30px 0px rgba(155,185,207,0.73);
  box-shadow: 2px 15px 30px 0px rgba(155,185,207,0.73);
  z-index: 10;
}

.header {
  display: flex;
  align-items: center; 
  background-color: white;
  max-width: 1140px;
  justify-content: space-between;
  margin: auto;
}

.footer {
  background: white;
}

.breadcrumb {
  display: flex;
  width: calc(100% /3);
  height: 100px;
  align-items: center; 
  background-image: linear-gradient(to right, #eae1e1 , #f7efef);
}

.moreGame {
  color: blue;
  cursor: pointer;
}

.blockContent {
  display: flex;
}

.collapse {
  width: calc(100%*2/3);
}

.contentBug {
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid #4582c0;
  border-bottom: 1px solid #4582c0;
}

.news {
  padding-left: 32px;
  width: calc(100% /3);
}

.loginType {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  width: calc(100% / 6);
}

.footer {
  height: 200px;
}

.ant-card-body {
  padding: 0px;
  box-shadow: 3px 10px 10px rgba(0,0,0,0.2);
}

.ant-tabs-tab {
  border-bottom: 1px solid #d0d0d0;
  margin: 0px !important;
}

.ant-tabs-tab-active {
  background-color: #c7eeff;
  margin: 0px;
}

.box-content {
  background-color: #f6f6f6;
  margin-left: 8px;
  padding: 8px;
  border-radius: 8px
}

.content {
  // background-color: white;
  // border: 1px solid #c7eeff;
  // padding: 0px 8px 8px 8px;
}

.ant-tabs-tab-btn {
  width: 100%
}

.webkit {
  overflow: scroll
}

.detailLeft::-webkit-scrollbar {
  width: 0em;
}

.webkit::-webkit-scrollbar {
  width: 0em;
}

.webkit::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

.ant-upload-list {
  display: flex
}

.ant-upload-list-item-container {
  max-width: 150px
}

//responsive
@media (max-width: 780px) {
  .content {
    padding: 16px
  }

  .collapse {
    width: 100%
  }

  .blockContent {
    flex-direction: column
  }

  .news {
    padding-top: 16px;
    padding-left: 0px;
    width: 100%
  }

  .homeTitle {
    display: none
  }

  .navTitle {
    font-size: 14px
  }
}

@media (max-width: 680px) {
  .listBread {
    display: flex;
    flex-direction: column;
  }

  .breadcrumb {
    border-radius: 16px;
    margin-top: 8px;
    width: 100%;
  }

  .ant-tabs-tab {
    border: 1px solid;
    padding: 8px
  }

  .ant-tabs-tab-active {
    border: 1px solid;
    padding: 8px
  }
}
`