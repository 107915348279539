export const bg = require('./img/bg.jpg')
export const cash = require('./img/cash.png')
export const account = require('./img/account.png')
export const engineering = require('./img/engineering.png')
export const bell = require('./img/bell.png')
export const cateBg = require('./img/cate-bg.jpg')
export const issue = require('./img/issue.png')
export const login = require('./img/login.png')
export const thanks = require('./img/thank.png')
export const bg_home = require('./img/bg_home.png')
export const logout_ic = require('./img/logout_ic.png')
export const game_development = require('./img/game_development.png')
export const bg_thoang_oder = require('./img/bg_thoang_order.jpg')
export const bg_login = require('./img/login_bg.png')
export const logo_tpl = require('./img/logo_tpl.png')
export const travel_agent = require('./img/travel_agent.png')
export const bot = require('./img/bot.png')
export const album = require('./img/album.png')
export const sent = require('./img/sent.png')
export const file = require('./img/attach_file.png')