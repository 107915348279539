import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { commentSilceActions as actions } from '.';
import { Client } from 'app/api/Client';
import { getListComment } from 'app/api/actions/game';

const PER_PAGE = 10;

function* _getListComment(action) {
  try {
    const { payload, loading } = yield Client.query(getListComment(
      action
    ))
    console.log('payload_list_comment', payload)
  } catch (error) {
  }
}

export function* commentSliceSaga() {
  yield takeLatest(actions.getListComment.type, _getListComment);
}
