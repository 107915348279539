/**
 *
 * SendTicket
 *
 */
import {
  AlignLeftOutlined
} from '@ant-design/icons';
import { Button, Card, Collapse, Empty, Form, Layout, Row, Select, Space, Spin, Tabs, Typography, message, notification } from "antd";
import { logout } from 'app/api/actions/authen';
import { getListComment, getListStatus, listFormTicket, listGame, listTicketSender, readComment, sendCommentTickets, ticketDetailSender } from "app/api/actions/game";
import moment from "moment";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useParameterizedQuery } from "react-fetching-library";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { bg, game_development, issue, login } from 'themes';
import { useGlobalSliceSlice } from '../../slice';
import { selectGlobalSlice } from '../../slice/selectors';
import YourTicket from './YourTicket.style';
import DetailTickets from './components/DetailTickets';
import ModalGame from './components/ModalGame';
import ModalLogin from './components/ModalLogin';
import ModalLogout from './components/ModalLogout';
import './modal.css';
import { useCommentSlice } from './slice';
import { useTranslation } from 'react-i18next';

interface Props { }

export const YourTickets = memo((props: Props) => {
  const { Header, Content, Footer, Sider } = Layout;
  const { Title } = Typography;
  const { Panel } = Collapse;
  const navigate = useNavigate();

  const [moreGame, setMoreGame] = useState<boolean>(false)
  const [loginModal, setLoginModal] = useState<boolean>(false)
  const [logoutModal, setLogoutModal] = useState<boolean>(false)

  const { query, payload } = useParameterizedQuery(listFormTicket)
  const { query: queryListGame, payload: payloadListGame } = useParameterizedQuery(listGame)
  const { query: queryListTicket, payload: payloadListTicketSender, loading: loadingListSender } = useParameterizedQuery(listTicketSender)
  const { query: queryTicketDetail, payload: payloadTicketDetailSender, loading } = useParameterizedQuery(ticketDetailSender)
  const { mutate, loading: loadingComment } = useMutation(sendCommentTickets)
  const { query: queryListComment, payload: payloadListComment } = useParameterizedQuery(getListComment)
  const { mutate: mutateLogout, loading: loadingLogout } = useMutation(logout)
  const { query: queryListStatus, payload: payloadListStatus, loading: loadingStatus } = useParameterizedQuery(getListStatus)

  const { query: queryReadComment, payload: payloadReadComment, loading: loadingReadComment } = useParameterizedQuery(readComment)

  const [ticketCode, setTicketCode] = useState<number>(!!payloadListTicketSender && payloadListTicketSender?.data?.collection[0]?.code)
  const [api, contextHolder] = notification.useNotification();

  const dispatch = useDispatch()
  const { actions } = useGlobalSliceSlice();
  const { actions: actionComments } = useCommentSlice();
  const { gameId, categoryId, categoryTypeId } = useSelector(selectGlobalSlice)
  const token = localStorage.getItem('token')
  const gameIdLocal = localStorage.getItem('gameId')
  const categoryIdLocal = localStorage.getItem('categoryId')
  const categoryTypeIdLocal = localStorage.getItem('categoryTypeId')
  const username = localStorage.getItem('username')
  const [form] = Form.useForm();
  const { state } = useLocation() as any;
  const [widthReality, setWidthReality] = useState({
    width: window.innerWidth || 0
  })
  const { i18n, t } = useTranslation();



  const openNoti = React.useCallback((type: string, message: string) => {
    api[type]({
      key: 'updatable',
      message: 'Thông báo',
      description: message || 'Vui lòng thử lại',
    })
  }, [])


  useMemo(() => {
    if (!!payloadListTicketSender && payloadListTicketSender?.status == "error") {
      navigate('/login', { state: 'your-tickets' })
      return
    }
    if (!!payloadListTicketSender?.data?.paginations?.links?.last_page_url.includes('null')) {
      openNoti('error', 'Vui lòng chọn lại game hoặc thay đổi sản phẩm')
      setMoreGame(true)
      return
    }
  }, [payloadListTicketSender])


  // console.log('payloadListTicketSender___', payloadListTicketSender);

  const detectSize = () => {
    setWidthReality({
      width: window?.innerWidth
    })
  }

  useEffect(() => {
    if (!token) {
      navigate('/login', { state: 'your-tickets' })
      return
    }
    queryListStatus({})
    queryListTicket({
      gameId: gameId || gameIdLocal,
      status: ''
    })
    queryListGame({ search: '' })
    query({
      gameId: gameId || gameIdLocal,
      category_id: categoryId || categoryIdLocal,
      category_type_id: categoryTypeId || categoryTypeIdLocal || state?.category_type_id
    })
    window?.addEventListener('resize', detectSize)
  }, [token])

  useEffect(() => {
    if (!ticketCode) {
      queryListComment({
        gameId: gameId || gameIdLocal || state?.gameId,
        ticketCode: payloadListTicketSender?.data?.collection[0]?.code,
        page: 1,
        perPage: 100
      })
      queryTicketDetail({
        gameId: gameId || gameIdLocal,
        ticketCode: payloadListTicketSender?.data?.collection[0]?.code
      })
      return
    }
  }, [payloadListTicketSender, ticketCode, gameId, state])

  useMemo(() => {
    queryListTicket({
      gameId: gameId || gameIdLocal,
      status: ''
    })
    query({
      gameId: gameId || gameIdLocal,
      category_id: categoryId || categoryIdLocal,
      category_type_id: categoryTypeId || categoryTypeIdLocal || state?.category_type_id
    })
    queryListStatus({})
    if (!ticketCode) {
      queryListComment({
        gameId: gameId || gameIdLocal || state?.gameId,
        ticketCode: payloadListTicketSender?.data?.collection[0]?.code,
        page: 1,
        perPage: 100
      })
      queryTicketDetail({
        gameId: gameId || gameIdLocal,
        ticketCode: payloadListTicketSender?.data?.collection[0]?.code
      })
      return
    }
  }, [i18n.language])

  console.log('payloadListComment___your::', payloadListComment);
  console.log('payloadTicketDetailSender___your::', payloadTicketDetailSender);


  console.log('ticket___', ticketCode);



  const openGame = useCallback(() => {
    setMoreGame(true)
  }, [moreGame])

  const loginGame = useCallback(() => {
    navigate('/login', { state: 'your-tickets' })
  }, [])

  const _saveModal = useCallback((game: any) => {
    if (!!game && game?.id) {
      dispatch(actions.saveGameId({
        gameId: game?.id
      }))
      localStorage.setItem('gameId', game?.id)
      queryListTicket({
        gameId: game?.id,
        status: ''
      })
      query({
        gameId: game?.id, //select Game
        category_id: categoryId || categoryIdLocal,
        category_type_id: categoryTypeId || categoryTypeIdLocal || state?.category_type_id
      })
    }
    setMoreGame(false)
  }, [categoryTypeId, categoryId, state, categoryIdLocal, categoryTypeIdLocal])




  //ACTION

  const _ticketDetail = useCallback((_ticket: any) => {
    setTicketCode(_ticket?.code)
    queryListComment({
      gameId: gameId || gameIdLocal || state?.gameId,
      ticketCode: _ticket?.code,
      page: 1,
      perPage: 100
    })
    queryTicketDetail({
      gameId: gameId || gameIdLocal,
      ticketCode: _ticket?.code
    })
    queryReadComment({
      gameId: gameId || gameIdLocal,
      ticketCode: _ticket?.code
    })
    queryListTicket({
      gameId: gameId || gameIdLocal,
      status: ''
    })
  }, [gameId, ticketCode, gameIdLocal])

  const _clickTicket = React.useCallback(() => {
    navigate('/your-tickets')
  }, [gameId])

  const sendComment = useCallback(async (values: any) => {
    form.resetFields()
    if (!values) return
    if (!gameId) {
      setMoreGame(true)
      message.warning(`${t('category.messProd')}`)
      return
    }
    console.log('values____Input', values)
    try {
      let { payload } = await mutate({
        // formValues: Object.keys(values).map(id => ({ value: values[id] || null, id: id || null })),
        formValues: {
          body: values[0],
          files: values[1] || []
        },
        gameId: gameId || state?.gameId,
        ticketCode: !!ticketCode ? ticketCode : payloadListTicketSender?.data?.collection[0]?.code,
      })
      if (payload.code == 401) {
        localStorage.removeItem('token')
        setLogoutModal(false)
        navigate('/login', { state: 'your-tickets' })
        return
      }
      if (payload?.code == 200) {
        // dispatch(actionComments.getListComment({
        //   gameId: gameId || state?.gameId,
        //   ticketCode: ticketCode,
        //   page: 1,
        //   perPage: 20
        // }))
        queryListComment({
          gameId: gameId || state?.gameId,
          ticketCode: !!ticketCode ? ticketCode : payloadListTicketSender?.data?.collection[0]?.code,
          page: 1,
          perPage: 100
        })
        return
        // openResult()
      }
      openNoti('error', payload?.message)
      if (payload?.code == 500) {
        openNoti('error', payload?.message)
      }
    } catch (error: any) {
      console.log('error', error)
    }
    form.resetFields()
  }, [gameId, ticketCode, state, payloadListTicketSender]);

  const logoutGame = useCallback(async (type: string) => {
    if (type == "close_modal") {
      setLogoutModal(false)
      return
    }
    try {
      let { payload } = await mutateLogout({})
      if (payload.code == 200) {
        localStorage.removeItem('token')
        console.log('payload___', payload)
        setLogoutModal(false)
        navigate('/your-tickets')
        return
      }
      if (payload.code == 401) {
        localStorage.removeItem('token')
        setLogoutModal(false)
        navigate('/login', { state: 'your-tickets' })
        return
      }
      openNoti('error', payload?.message)
    } catch (error) {
      // console.log('error__logout', error);
    }
  }, [logoutModal])

  return <YourTicket>
    {contextHolder}
    <Layout style={{ minHeight: '100vh' }}>
      {/* <Header className='header-container' style={{ backgroundColor: 'white' }}>
        <div className="header">
          <Title className='navTitle' onClick={() => navigate('/')} style={{ margin: 0, cursor: 'pointer' }} level={3}>SUPPORT</Title>
          <div className="" style={{ display: 'flex', alignItems: 'center' }}>
            <Button className='homeTitle' onClick={() => navigate('/')} type="text" block>
              Trang chủ
            </Button>
            <Button onClick={() => _clickTicket()} type="text" block>
              Yêu cầu đã gửi
            </Button>
            <Space style={{}} >
              <img style={{ height: 40, width: 40, zIndex: 10, borderRadius: 50, marginLeft: 8 }} src={!!payloadListGame && payloadListGame?.data?.collection?.find((_game: any) => _game?.id == gameIdLocal || _game?.id == gameId)?.icon_url || game_development} />
            </Space>
          </div>
        </div>
      </Header> */}
      <Content className='content' style={{ backgroundColor: 'white' }}>
        <div className="" style={{ maxWidth: '1140px', margin: 'auto', paddingTop: 16 }}>
          <div className="listBread" style={{ display: 'flex' }}>
            <ModalGame closeModal={(game) => _saveModal(game)} open={moreGame} setOpen={openGame} />
            <ModalLogin closeModal={() => setLoginModal(false)} openLogin={loginModal} setOpenLogin={loginGame} />
            <ModalLogout closeModal={(type) => logoutGame(type)} open={logoutModal} />
            <div className="breadcrumb" style={{ borderTopLeftRadius: 16, borderBottomLeftRadius: 16 }}>
              <img style={{ height: 64, width: 64, marginLeft: 16 }} src={!!payloadListGame ? `${payloadListGame?.data?.collection?.find((_game: any) => _game?.id == gameIdLocal)?.icon_url}` : game_development} />
              <div className="" style={{ paddingLeft: 16 }}>
                <Title style={{ fontSize: 14 }}>
                  {!!payloadListGame && payloadListGame?.data?.collection?.find((_game: any) => _game?.id == gameIdLocal || _game?.id == gameId)?.name || ""}
                </Title>
                <div onClick={() => openGame()} className="moreGame">{t('tickets.changeProd')}</div>
              </div>
            </div>
            <div className="breadcrumb">
              <Space style={{}} >
                <img style={{ height: 64, width: 64, marginLeft: 16 }} src={issue} />
              </Space>
              <div onClick={() => navigate('/category')} className="" style={{ paddingLeft: 16 }}>
                <Title style={{ fontSize: 14, }}>
                  {t('tickets.chooseIssue')}
                </Title>
                <p style={{ cursor: 'pointer', margin: 0, color: 'blue' }} className="">{t('tickets.listTickets')}</p>
              </div>
            </div>
            <div className="breadcrumb" style={{ borderTopRightRadius: 16, borderBottomRightRadius: 16 }}>
              <Space style={{}} >
                <img style={{ height: 64, width: 64, marginLeft: 16 }} src={login} />
              </Space>
              <div className="" style={{ paddingLeft: 16 }}>
                {!!token ? <div className="">
                  <Title style={{ fontSize: 14 }}>
                    {t('tickets.hello')} {username || ""}
                  </Title>
                  <div onClick={() => setLogoutModal(true)} className="moreGame">{t('tickets.logOut')}</div>
                </div> : <div>
                  <Title style={{ fontSize: 14 }}>
                    {t('tickets.unLogin')}
                  </Title>
                  <div onClick={() => loginGame()} className="moreGame">{t('tickets.logIn')}</div>
                </div>}
              </div>
            </div>
          </div>
          <div className="" style={{ marginTop: 16, paddingLeft: widthReality?.width < 980 ? 16 : 0, paddingRight: widthReality?.width < 980 ? 16 : 0, }}>
            <Card loading={loadingStatus} style={{ padding: 0 }}>
              {/* <Space direction="vertical" style={{ width: '50%' }}> */}
              {!!payloadListStatus &&
                <Select
                  defaultValue={`${t('tickets.statusAll')}`}
                  style={{ margin: '8px 16px 8px 16px', width: '33%' }}
                  onChange={(value) => {
                    queryListTicket({
                      gameId: gameId || gameIdLocal,
                      status: value || ""
                    })
                  }}
                  options={Object.entries((Object?.assign(!!payloadListStatus && payloadListStatus?.data?.status, { "": t('tickets.statusAll') }) || [])).map(([value, label]) => ({
                    value,
                    label
                  }))}
                />
              }
              {/* </Space> */}
              <Spin spinning={loadingListSender}>
                {!!payloadListTicketSender && payloadListTicketSender?.data?.paginations?.meta?.total == 0 ?
                  <Tabs
                    tabPosition={!!widthReality && widthReality?.width < 480 ? 'top' : 'left'}
                    style={{ height: !!widthReality && widthReality?.width < 480 ? 680 : 515, width: '33%' }}
                    items={[
                      {
                        label: <div style={{}}>
                          <Title style={{ fontSize: 14, fontWeight: 400, margin: 0, textAlign: 'start' }}>
                            {`${t('tickets.noSupport')}`}
                          </Title>
                        </div>,
                        key: '0',
                        children: <Empty style={{ margin: 16 }} />
                      },
                    ]}
                  /> : <Tabs
                    tabPosition={!!widthReality && widthReality?.width < 480 ? 'top' : 'left'}
                    style={{ height: !!widthReality && widthReality?.width < 480 ? 680 : 515 }}
                    items={!!payloadListTicketSender && payloadListTicketSender?.data?.collection?.map((_ticket: any, index: number) => {
                      return {
                        label: <div style={{}}>
                          <div style={{ margin: 0, display: 'flex', flexDirection: 'column', maxWidth: 280 }} onClick={() => _ticketDetail(_ticket)} >
                            <div style={{ display: 'flex' }} >
                              <div className="" style={{ flex: 1 }}>
                                <Title style={{ fontSize: 14, fontWeight: 400, margin: 0, textAlign: 'start' }}>
                                  {_ticket?.code || ""}
                                </Title>
                                <Title style={{ fontSize: 14, color: '#e16236', fontWeight: 'normal', marginTop: 10, marginBottom: 0, textAlign: 'start' }}>
                                  {_ticket?.status_name || ""}
                                </Title>
                              </div>
                              <Title style={{ fontSize: 14, color: '#7f7f7f', fontWeight: 'normal', margin: 0, paddingLeft: 8, textAlign: 'start' }}>
                                {moment(_ticket.created_at).format('DD/MM/YYYY')}
                                {_ticket?.count_unread > 0 &&
                                  <div style={{ borderRadius: 100, position: 'absolute', top: 2, right: 2, justifyContent: 'center', alignItems: 'center', }} >
                                    <p style={{ color: 'white', fontSize: 10, border: '1px solid red', borderRadius: 100, width: 18, textAlign: 'center', backgroundColor: 'red' }} className="">{_ticket?.count_unread}</p>
                                  </div>
                                }
                              </Title>
                            </div>
                            <div style={{}} >
                              <Title style={{ fontSize: 14, color: '#7f7f7f', fontWeight: 'normal', marginTop: '8px', textAlign: 'start' }}>
                                {_ticket?.category_type?.name || ""}
                              </Title>
                            </div>
                          </div>
                        </div>,
                        key: index,
                        children: <DetailTickets widthReality={!!widthReality && widthReality?.width} form={form} dataComment={!!payloadListComment && payloadListComment?.data} onFinishComment={sendComment} loading={loading} data={payloadTicketDetailSender?.data || []} />
                      };
                    })}
                  />
                }
              </Spin>
            </Card>
          </div>
          {/* <Title level={5} style={{ fontWeight: 'bold', color: '#4582c0', paddingTop: 16, paddingBottom: 16, margin: 0 }}>
            {!!payload && payload?.data?.name || ""}
          </Title> */}
        </div>
      </Content >
      {/* <Footer className='footer' style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'repeat-x' }}>
        <div className="" style={{ maxWidth: '1140px', margin: 'auto' }}>
          <Row justify="space-between" align={'middle'}>
            <div className="">
              <AlignLeftOutlined style={{ fontSize: 20 }} />
              <Title style={{ fontWeight: 'normal', fontSize: 14 }}>
                Tiếng việt
              </Title>
            </div>
            <div className="">
              <a href="" className="">
                <Title style={{ fontWeight: 'normal', fontSize: 14, color: 'rgb(69, 130, 192)' }}>
                  Giải quyết quy trình khiếu nại
                </Title>
              </a>
              <Title style={{ fontWeight: 'normal', fontSize: 14 }}>
                Copyright © 2020 Corp. All rights reserved.
              </Title>
            </div>
          </Row>
        </div>
      </Footer> */}
    </Layout >
  </YourTicket >
});
